import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  
  constructor(private snackBar: MatSnackBar) { }

  showMessage(message: string, type: 'error' | 'success'): void {
  
    this.snackBar.open(
      message,
      type ==='error' ? 'Close' : '',
      {
        duration: type ==='error' ? 0 : 2500,
        panelClass: type ==='error' ? 'err-msg' : 'sucs-msg',
        horizontalPosition: 'center',
        verticalPosition: 'top',
      }
    );
  }
}
